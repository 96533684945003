export default () => {
  const getIpLocation = async() => {
    const response = await useApi.get('https://get.geojs.io/v1/ip/geo.json').catch(() => false);

    return response;
  };

  const hasAccessToEmergency = async() => {
    if (process.env.NODE_ENV !== 'production') {
      return true;
    }

    const geolocation = await getIpLocation();

    return !geolocation || (geolocation.continent_code && geolocation.continent_code === 'EU');
  };

  const accessToActionDeniedMessage = () => {
    ElMessage.error({
      message: 'K této akci nemáte dostatnečná oprávnění. Kontaktujte <a href="mailto:info@tichalinka.cz">info@tichalinka.cz</a>',
      dangerouslyUseHTMLString: true,
      duration: 4000,
      showClose: true,
    });
  };

  return {
    getIpLocation,
    hasAccessToEmergency,
    accessToActionDeniedMessage,
  };
};
